/* global localStorage */
import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import { connect } from 'react-redux'
import { loginAction, logoutAction, rxnavToggle } from './../../redux'
import { apipost, rxsetCookie, rxsetLocal } from './../../classes/request'

class loginComponent_ extends Component {
  constructor (props) {
    super(props)

    this.mounted = true
    this.state = {
      editingData: {},
      msg: '',
      showPopup: false,
      checked: false
    }
  }

  componentDidMount () {
    this._ismounted = true
    if (localStorage.checkbox && localStorage.checkbox !== '') {
      let editingData = this.state.editingData
      editingData.username = localStorage.username
      editingData.password = localStorage.password

      this.setState({checked: true, editingData: editingData })
    } else {
      let editingData = this.state.editingData
      editingData.username = ''
      editingData.password = ''

      this.setState({checked: false, editingData: editingData })
    }
  }
  componentWillUnmount () { this._ismounted = false }

  onBlurData (e, name) {
    let editingData = this.state.editingData
    editingData[name] = e.target.value
    this.setState({ editingData: editingData })
  }

  onClickLoginSubmit (e) {
    let editingData = this.state.editingData
    this.setState({ editingData: editingData }, () => {
      apipost(global.rxu.get(global.rxu.config, 'api_authorize'), this.state.editingData, {
        '1': (json) => {
          if (this._ismounted) {
            (json.data && json.data.user) ? rxsetCookie('authorize', json.data.user.authorization, 30) : rxsetCookie('authorize', json.data.authorization, 30)
            rxsetLocal('arrper', json.data.arrper)
            rxsetLocal('rxuser', json.data)

            this.setState({ msg: '' })
            json.data = { ...json.data }
            this.props.loginAction(json.data)
            let role = global.rxu.get(json.data, 'user.role', 'member')
            role = role.toLowerCase()
            console.log(role, '123123')
            switch (role) {
              case 'super':
              case 'superedit':
              case 'profileedit':
                this.props.history.push('/admin/card')
                this.props.rxnavToggle('ex_manager_card')
                break
              case 'lanhdaobo':
                this.props.history.push('/admin/review_8')
                this.props.rxnavToggle('ex_manager')

                break
              case 'lanhdaocuc01':
              case 'lanhdaocucbcdp':
              case 'lanhdaocucbctw':
              case 'lanhdaocucptth':
                this.props.history.push('/admin/review_3')
                this.props.rxnavToggle('ex_manager')
                break
              case 'lanhdaodonvi':
              case 'lanhdaodp':
              case 'lanhdaotw':
              case 'lanhdaopt':
                this.props.history.push('/admin/review_2')
                this.props.rxnavToggle('ex_manager')
                break
              case 'viewer':
              case 'member':
                this.props.history.push('/admin/card')
                this.props.rxnavToggle('ex_manager_card')
                break
              case 'printer':
                this.props.history.push('/admin/printlist')
                this.props.rxnavToggle('ex_manager')
                break
              default:
                this.props.history.push('/admin/review_1')
                this.props.rxnavToggle('ex_manager')
            }
          }
        },
        'default': (json) => {
          if (this._ismounted) {
            var strmsg = ''
            strmsg = json.msg
            this.props.logoutAction()
            this.setState({ msg: strmsg })
          }
        }
      })
    })
  }
  rememberMe (e) {
    let checked = !this.state.checked
    console.log(checked)
    if (checked) {
      localStorage.checkbox = checked
      localStorage.username = this.state.editingData.username
      localStorage.password = this.state.editingData.password
      this.setState({checked: checked})
    } else {
      localStorage.checkbox = ''
      localStorage.username = ''
      this.setState({checked: checked})
    }
  }
  onClickShowPopup () {
    this.setState({showPopup: true})
  }

  render () {
    return (<div>
      {/* <div className='adtopbar' style={{backgroundPositionX: '0'}}>
        <div className='adtopbar__title' style={{paddingLeft: '30px'}}>Hệ thống quản lý Hồ sơ thẻ nhà báo</div>
        <div className='adtopbar__right'>
          <a href='/card' className='adtopbar__addcard' style={{marginRight: '2px'}}><i className='iconcustom-create betable__addbtn' />Thêm mới thẻ</a>
          <Link to='/register'  className='adtopbar__addcard'><div>Đăng ký</div></Link>
          <Link to='/manual' className='adtopbar__addcard'><div>Hướng dẫn sử dụng</div></Link>
        </div>
      </div> */}
      <div className='rx-authpage'>
        {/* {!this.state.showPopup ? <div className='authpage__toggle'>
          <div className='authform__head'>Đăng nhập</div>
          <div className='authpage__toggle-body'>
            <div className='authpage__item rx-cursor' onClick={(e) => this.onClickShowPopup()}>
              <img src='/images/static/logo.jpg' style={{width: '70px'}} alt='Đăng nhập vào hệ thống quản lý' />
              <div>Đăng nhập vào hệ thống quản lý</div>
            </div>
            <a className='authpage__item' href='https://xacthuc.mic.gov.vn/oauth2/authorize?response_type=code&client_id=WnBFo2Bn2jbbgAkRLiES6LC6mH8a&redirect_uri=https://cmsthenhabao.mic.gov.vn/authorize/authorizeSSO&scope=openid' target='_blank' rel="noopener noreferrer">
              <img src='/images/static/logo.jpg' alt='Đăng nhập bằng tài khoản MicConnect' />
              <div>Đăng nhập bằng tài khoản MicConnect</div>
            </a>
          </div>
        </div> : */}
        <div className='authloginpage'>
          <form>
            {/* }<div className='authform__head'>Hệ thống hiện đang bảo trì từ ngày 17/05/2024 đến ngày 20/05/2024. <br/>
            Đề nghị cơ quan báo chí thực hiện đăng nhập lại sau! Xin cảm ơn! </div>  
            <div className='authform__head'>Hệ thống hiện đang bảo trì từ ngày 17/05/2024 đến ngày 20/05/2024. <br/>
            Đề nghị cơ quan báo chí thực hiện đăng nhập lại sau! Xin cảm ơn! </div>*/}
            
            <div className='authform' onKeyPress={(e) => { let charCode = e.which || e.charCode || e.keyCode || 0; if (charCode === 13) this.onClickLoginSubmit(e) }}>
              <div className='authform__head'>Đăng nhập</div>
              <p className='authform_msgerror'>{this.state.msg}</p>
              <input tabIndex='1' type='text' placeholder='Tài khoản' className='authform__input' value={this.state.editingData.username || ''} autoComplete='username' onChange={(e) => this.onBlurData(e, 'username')} />
              <input tabIndex='2' type='password' placeholder='Mật khẩu' className='authform__input' value={this.state.editingData.password || ''} autoComplete='password' onChange={(e) => this.onBlurData(e, 'password')} />
              <div tabIndex='3' className='authform__btnsubmit' onClick={(e) => { this.onClickLoginSubmit(e) }} onKeyPress={(e) => { this.onClickLoginSubmit(e) }}>Đăng Nhập</div>
              <input type='checkbox' checked={this.state.checked ? 'checked' : ''} onChange={(e) => { this.rememberMe(e) }} id='rememberMe' className='authform__input checkbox' /> <label htmlFor='rememberMe'>Remember me</label>
              {<p className='authform__msg'>Bạn chưa có tài khoản? <Link to='/register'>Đăng ký tài khoản</Link></p>}
              {<p className='authform__msg'><a target='_blank' rel='noopener noreferrer' href='https://xacthuc.mic.gov.vn/oauth2/authorize?response_type=code&client_id=WnBFo2Bn2jbbgAkRLiES6LC6mH8a&redirect_uri=https://cmsthenhabao.mic.gov.vn/authorize/authorizeSSO&scope=openid'>Đăng nhập bằng tài khoản MicConnect</a></p>}
              {<p className='authform__msg'><Link to='/password'>Quên mật khẩu?</Link></p>}
            </div>
            
          </form>
        </div>{/* } */}
      </div>
    </div>)
  }
}

const mapStateToProps = (state, ownProps) => ({
  user: state.user
})

const mapDispatchToProps = {
  logoutAction,
  loginAction,
  rxnavToggle
}

const loginComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(loginComponent_)

export default loginComponent
