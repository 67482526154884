export function rxsecret () {
  let result = []
  result.web_port = 12000
  // result.base_api = 'http://178.128.122.22:8886'
  // result.base_qrcode = 'https://thenhabao.mic.gov.vn/kiemtra?id='
  result.base_api = 'http://203.162.120.71:8886'
  result.base_qrcode = 'https://tnb-cms.the-farm.vn/kiemtra?id='
  result.theme = 'default'
  return result
}
