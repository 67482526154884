export function rxsecret () {
  const result = []
  result.web_port = 12000
  // result.base_api = 'http://178.128.122.22:8886'
  // result.base_api = 'https://cmsthenhabao.mic.gov.vn'
  result.base_api = 'https://tnb-api.the-farm.vn'
  result.theme = 'default'
  // result.base_qrcode = 'https://thenhabao.mic.gov.vn/kiemtra?id='
  result.base_qrcode = 'https://tnb-cms.the-farm.vn/kiemtra?id='
  result.base_ios = ''
  result.base_android = 'https://play.google.com/store/apps/details?id=vn.netacom.journalist&hl=vi'
  return result
  // https://xacthuc.mic.gov.vn/oauth2/authorize?response_type=code&client_id=WnBFo2Bn2jbbgAkRLiES6LC6mH8a&redirect_uri=http://203.162.121.124:8886/authorize/authorizeSSO&scope=openid
}
